<template>
  <b-container fluid>
    <bo-page-title />
    <section id="webSettings">
      <validation-observer v-slot="{ handleSubmit }" ref="VFormSocial">
        <b-form @submit.prevent="handleSubmit(submitSocial)">
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Social Media Information</h5>
            </template>
            <b-card-body>
              <b-row>
                <b-col md=4>
                  <b-form-group label-for="linkedIn" label="LinkedIn Link">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text><i class="ti-linkedin"></i></b-input-group-text>
                      </template>
                      <b-form-input v-model="socialInformation.linkedin" id="linkedIn"/>
                    </b-input-group>
                      <VValidate 
                        name="LinkedIn Link" 
                        v-model="socialInformation.linkedin"
                        :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                      />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="facebook" label="Facebook Link">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text><i class="ti-facebook"></i></b-input-group-text>
                      </template>
                      <b-form-input v-model="socialInformation.facebook" id="facebook"/>
                    </b-input-group>
                      <VValidate 
                        name="Facebook Link" 
                        v-model="socialInformation.facebook"
                        :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                      />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="instagram" label="Instagram Link">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text><i class="ti-instagram"></i></b-input-group-text>
                      </template>
                      <b-form-input v-model="socialInformation.instagram" id="instagram"/>
                    </b-input-group>
                      <VValidate 
                        name="Instagram Link" 
                        v-model="socialInformation.instagram"
                        :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                      />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="twitter" label="Twitter Link">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text><i class="ti-twitter"></i></b-input-group-text>
                      </template>
                      <b-form-input v-model="socialInformation.twitter" id="twitter"/>
                    </b-input-group>
                      <VValidate 
                        name="Twitter Link" 
                        v-model="socialInformation.twitter"
                        :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                      />
                  </b-form-group>
                </b-col>
                <b-col md=4>
                  <b-form-group label-for="youtube" label="Youtube Link">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text><i class="ti-youtube"></i></b-input-group-text>
                      </template>
                      <b-form-input v-model="socialInformation.youtube" id="youtube"/>
                    </b-input-group>
                      <VValidate 
                        name="Youtube Link" 
                        v-model="socialInformation.youtube"
                        :rules="{regex:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/}" 
                      />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-2">Cancel</b-button>              
                <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-form>
      </validation-observer>

      <validation-observer v-slot="{ handleSubmit }" ref="VFormContact">
        <b-form @submit.prevent="handleSubmit(submitContact)">
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Contact Information</h5>
            </template>
            <b-card-body>
              <b-row>
                <b-col md=6>
                  <b-form-group label-for="mainOffice">
                    <label>Main Office Address <span class="text-danger mr5">*</span></label>
                    <b-form-textarea rows=4 v-model="contactInformation.office_address"></b-form-textarea>
                    <VValidate name="Main Office Address" v-model="contactInformation.office_address" rules="required|min:5" />
                  </b-form-group>
                </b-col>
                <b-col md=6>
                  <b-form-group label-for="factory">
                    <label>Factory Address <span class="text-danger mr5">*</span></label>
                    <b-form-textarea rows=4 v-model="contactInformation.factory_address"></b-form-textarea>
                    <VValidate name="Factory Address" v-model="contactInformation.factory_address" rules="required|min:5" />
                  </b-form-group>
                </b-col>
                <b-col md=6>
                  <b-card no-body>
                    <b-card-header>
                      <b-card-title title-tag="p">Phone Number</b-card-title>
                    </b-card-header>
                    <b-table-simple striped>
                      <tr>
                        <th width="70%">Phone</th>
                        <th width="30%">Action</th>
                      </tr>
                      <tr v-for="(value, index) in contactInformation.phone" :key='index'>
                        <td>
                          <template v-if="value.isNew">
                            <b-form-group>
                              <b-form-input 
                              :formatter="e => String(e).replace(/[^0-9()\+\-\s]+/g, '')"
                              v-model="value.value"></b-form-input>
                              <VValidate :ref="'phone'+index" name="Phone" :vid="'phone'+index" v-model="value.value" rules="required|min:3" />
                            </b-form-group>
                          </template>
                          <template v-else>
                            {{value}}
                          </template>
                        </td>
                        <td>
                          <b-button
                            v-if="!value.isNew"
                            variant="outline-warning"
                            pill
                            class="btn-icon mr-2"
                            v-b-tooltip.hover="'Update'"
                            @click="updateContact(value, index, 'phone')"
                          >
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            pill
                            class="btn-icon mr-2"
                            v-b-tooltip.hover="'Delete'"
                            @click="deleteContact(index, 'phone')"
                          >
                            <i class="fas fa-trash" />
                          </b-button>
                          <b-button
                            v-if="value.isNew"
                            variant="outline-success"
                            pill
                            class="btn-icon"
                            v-b-tooltip.hover="'Finish'"
                            @click="finishContact(value, index, 'phone')"
                          >
                            <i class="fas fa-check" />
                          </b-button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-center">
                          <b-button class="btn-rounded" type="button" @click="addContact('phone')" variant="outline-success">Add Phone</b-button>
                        </td>
                      </tr>
                    </b-table-simple>
                  </b-card>
                </b-col>
                <b-col md=6>
                  <b-card no-body>
                    <b-card-header>
                      <b-card-title title-tag="p">Email Address</b-card-title>
                    </b-card-header>
                    <b-table-simple striped>
                      <tr>
                        <th width="70%">Email</th>
                        <th width="30%">Action</th>
                      </tr>
                      <tr v-for="(value, index) in contactInformation.email" :key='index'>
                        <td>
                          <template v-if="value.isNew">
                            <b-form-group>
                              <b-form-input v-model="value.value"></b-form-input>
                              <VValidate :ref="'email'+index" name="Email" :vid="'email'+index" v-model="value.value" rules="required|email" />
                            </b-form-group>
                          </template>
                          <template v-else>
                            {{value}}
                          </template>
                        </td>
                        <td>
                          <b-button
                            v-if="!value.isNew"
                            variant="outline-warning"
                            pill
                            class="btn-icon mr-2"
                            v-b-tooltip.hover="'Update'"
                            @click="updateContact(value, index, 'email')"
                          >
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            pill
                            class="btn-icon mr-2"
                            v-b-tooltip.hover="'Delete'"
                            @click="deleteContact(index, 'email')"
                          >
                            <i class="fas fa-trash" />
                          </b-button>
                          <b-button
                            v-if="value.isNew"
                            variant="outline-success"
                            pill
                            class="btn-icon"
                            v-b-tooltip.hover="'Finish'"
                            @click="finishContact(value, index, 'email')"
                          >
                            <i class="fas fa-check" />
                          </b-button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-center">
                          <b-button class="btn-rounded" type="button" @click="addContact('email')" variant="outline-success">Add Email</b-button>
                        </td>
                      </tr>
                    </b-table-simple>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col md=4>
                  <iframe width="400" height="250" :src="contactInformation.link_gmaps"></iframe>
                  <b-form-group>
                    <label>Google Maps <span class="text-danger mr5">*</span></label>
                    <b-form-textarea v-model="contactInformation.link_gmaps"></b-form-textarea>
                    <VValidate name="Google Maps" v-model="contactInformation.office_address" rules="required" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-2">Cancel</b-button>              
                <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-form>
      </validation-observer>

      <validation-observer v-slot="{ handleSubmit }" ref="VFormSetting">
        <b-form @submit.prevent="handleSubmit(submitSetting)">
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Website Setting</h5>
            </template>
            <b-card-body>

              <b-card no-body border>
                <template #header>
                  <h5 class="card-title">Certificate</h5>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col lg="3" md="4" class="slider-item" v-for="(value, index) in setting.certificates" :key="index">
                      <div class="card-img">
                        <b-img fluid class="card-img__media" :src="uploader(value)" :blank="!value" blank-color="#ccc"/>
                        <div class="bullet-cta">
                          <b-button
                            variant="secondary"
                            size="sm"
                            pill
                            class="btn-icon"
                            triggers="hover"
                            v-b-tooltip.hover="'Update'"
                            @click="editCertificate(value)"
                          >
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button
                            variant="danger"
                            size="sm"
                            pill
                            class="btn-icon"
                            v-b-tooltip.hover="'Delete'"
                            @click="deleteCertificate(index)"
                          >
                            <i class="far fa-trash-alt" />
                          </b-button>
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="3" md="4">
                      <a href="javascript:;" class="card-img-adder" @click="addCertificate">
                        <i class="fas fa-plus"></i>
                        <span>Add Certificate</span>
                      </a>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-card-body>
            <b-card-footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-2">Cancel</b-button>              
                <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-form>
      </validation-observer>
    </section>
    <b-modal id="modalCertificate" title="Form Certificate" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormCertificate"
      >
        <b-form @submit.prevent="handleSubmit(submitCertificate)">
          <b-card-body>
            <b-row>
              <b-col md=12>
                <div class="form-group">
                  <label class="control-label">Image <span class="text-danger">*</span></label>         
                  <Uploader v-model="dataModal.image" type="certificate_footer"/>
                  <VValidate 
                    name="Image" 
                    v-model="dataModal.image"
                    rules="required" 
                  />
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="submitCertificate" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'

// import draggable from 'vuedraggable'

export default {
  extends: GlobalVue,
  components: {
    // draggable
  },
  data() {
    return {
        noTel: 0,
        socialInformation: {},
        contactInformation: {},
        setting:{},
        dataModal: {}
    }
  },
  methods: {
    addCertificate(){
      this.dataModal = {}
      this.$bvModal.show('modalCertificate')
    },
    editCertificate(value){
      this.dataModal = {image: value}
      this.$bvModal.show('modalCertificate')
    },
    deleteCertificate(index){
      this.setting.certificates.splice(index, 1)
    },
    submitCertificate(){
      this.$refs.VFormCertificate.validate().then(success => {
        if(!success) return

        this.setting.certificates.push(this.dataModal.image)
        this.$bvModal.hide('modalCertificate')
      })
    },
    addContact(type){
      this.contactInformation[type].push({value: '', isNew: true})
    },
    deleteContact(index, type){
      this.contactInformation[type].splice(index, 1)
    },
    updateContact(value, index, type){
      this.$set(this.contactInformation[type], index, {
        value,
        isNew: true
      })
    },
    finishContact(value, index, type){
      this.$refs[type+index][0].validate().then(success => {
        if(!success.valid) return

        this.contactInformation[type][index] = value.value
      })
    },
    submitSocial(){
      this.$refs.VFormSocial.validate().then(success => {
        if(!success) return

        this.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, { data: { type: 'update_social', ...this.socialInformation } })
        .then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success'
            }).then(() => {
              this.apiGet()
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon: 'error'})
        })
      })
    },
    submitContact(){
      this.$refs.VFormContact.validate().then(success => {
        if(!success) return

        this.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, { data: { type: 'update_contact', ...this.contactInformation } })
        .then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success'
            }).then(() => {
              this.apiGet()
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon: 'error'})
        })
      })
    },
    submitSetting(){
      this.$refs.VFormSetting.validate().then(success => {
        if(!success) return

        this.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, { data: { type: 'update_setting', ...this.setting } })
        .then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success'
            }).then(() => {
              this.apiGet()
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon: 'error'})
        })
      })
    }

  },
  mounted() {
    this.apiGet()      
  },
  watch:{
    $route(){
        this.apiGet()
    }
  }
}
</script>
